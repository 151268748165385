import ProductEventItem from "./ProductEventItem";

export default class ProductEvent {
  currency: string;
  value: number;
  items: ProductEventItem[];

  constructor(currency: string, value: number, items: ProductEventItem[]) {
    this.currency = currency;
    this.value = value;
    this.items = items;
  }
}
