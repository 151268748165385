import t from "@/app/hooks/lang";
import CartProduct from "./CartProduct";
import CartSuborder from "../../../Domain/CartSuborder";
import CartSendingBanner from "./CartSendingBanner";
import CartItem from "../../../Domain/CartItem";
import CartDifferentSellerBanner from "./CartDifferentSellerBanner";
import Link from "next/link";
import Image from "next/image";
import ArrowRight from "@/app/assets/images/arrows/arrow-right-active.svg";
import CartContent from "../../../Domain/CartContent";

interface Props {
  lang: string;
  suborders: CartSuborder[];
  inWidget?: boolean;
  setCartContent?: (CartContent: CartContent) => void;
}

const CartSuborders: React.FC<Props> = ({
  lang,
  suborders,
  inWidget,
  setCartContent,
}) => {
  return (
    <div className={`cart-products ${inWidget && "cart-products-widget"}`}>
      {!inWidget && (
        <div className="row">
          <div className="col-md-5 col-8">
            <h3>{t("product", lang)}</h3>
          </div>
          <div className="col-md-3 d-none d-md-block">
            <h3>{t("quantity", lang)}</h3>
          </div>
          <div className="col-4">
            <h3>{t("subtotal", lang)}</h3>
          </div>
        </div>
      )}
      {!inWidget && <hr />}
      {suborders.length > 1 && !inWidget && (
        <CartDifferentSellerBanner lang={lang} />
      )}
      {suborders.map((suborder: CartSuborder, i: number) => (
        <div key={i} className="cart-product">
          {!inWidget && <h2>{suborder.shop.name}</h2>}
          {suborder.items.map((item: CartItem, j: number) => (
            <CartProduct
              key={j}
              item={item}
              inWidget={inWidget}
              setCartContent={setCartContent}
            />
          ))}
          {!inWidget && (
            <CartSendingBanner lang={lang} country={suborder.shop.country} />
          )}
          {/* <div className="checkout-seller">
            <Link href={"/"}>{t("checkoutThisSeller", lang)}</Link>
            <Image src={ArrowRight} alt="Arrow Right" />
          </div> */}
          <hr />
        </div>
      ))}
    </div>
  );
};

export default CartSuborders;
