export default class WishList {
  wishlist: number[];
  constructor(wishlist: number[]) {
    this.wishlist = wishlist;
  }

  public static create(wishlistResponse: any): WishList {
    return new WishList(wishlistResponse.wishlist);
  }

  public static default = new WishList([]);
}
