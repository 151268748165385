import "../Assets/Css/Switcher.css";
import React, { ChangeEvent } from "react";

interface BuyOptionsSwitcherProps {
  selected?: number;
  removeValues: () => void;
  setValueInput: (value: string) => void;
  value: string | number;
  addValues: () => void;
}

const BuyOptionsSwitcher: React.FC<BuyOptionsSwitcherProps> = ({
  selected,
  removeValues,
  setValueInput,
  value,
  addValues,
}) => {
  return (
    <div
      className={selected !== 1 ? "plus-min-sel" : "plus-min-sel sample-center"}
    >
      {!selected || selected === 0 ? (
        <>
          <button onClick={removeValues}>-</button>
          <input
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              setValueInput(e.target.value)
            }
            type="text"
            value={value}
          />
          <button onClick={addValues}>+</button>
        </>
      ) : (
        <span>1</span>
      )}
    </div>
  );
};

export default BuyOptionsSwitcher;
