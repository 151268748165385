"use client";
import t from "@/app/hooks/lang";
import Switcher from "@/Core/Shared/Switcher/Infrastructure/Ui/Components/Switcher";
import { useState } from "react";

interface Props {
  quantity: number;
  setQuantity: (quantity: number) => void;
  updateCart: (quantity: number) => void;
  max: number;
  min: number;
}

const CartProductSwitcher: React.FC<Props> = ({
  quantity,
  setQuantity,
  updateCart,
  max,
  min,
}) => {
  const [errorMsg, setErrorMsg] = useState<string>("");

  const updateQuantity = (newQuantity: number): void => {
    setQuantity(newQuantity);
    updateCart(newQuantity);
    setErrorMsg("");
  };

  const handleError = (message: string): void => {
    setErrorMsg(message);
  };

  const addTotal = (): void => {
    const newQuantity = quantity + 1;
    if (newQuantity > max) {
      handleError(`${t("maxQuantCart")} ${max}`);
      return;
    }
    updateQuantity(newQuantity);
  };

  const removeTotal = (): void => {
    const newQuantity = quantity - 1;
    if (newQuantity < min) {
      handleError(`${t("minQuantCart")} ${min}`);
      return;
    }
    updateQuantity(newQuantity);
  };

  const setValueInput = (value: string): void => {
    const parsedValue = parseInt(value);
    if (parsedValue < min || value === "") {
      updateCart(min);
      setQuantity(min);
      handleError(`${t("minQuantCart")} ${min}`);
      return;
    }
    if (max === 0) {
      updateCart(0);
      setQuantity(0);
      handleError(t("noProductStock"));
      return;
    }
    if (parsedValue > max) {
      updateCart(max);
      setQuantity(max);
      handleError(`${t("maxQuantCart")} ${max}`);
      return;
    }
    if (isNaN(parsedValue)) {
      updateQuantity(1);
    } else {
      updateQuantity(parsedValue);
    }
  };

  return (
    <div className="cart-product-switcher">
      <Switcher
        addValues={addTotal}
        removeValues={removeTotal}
        setValueInput={setValueInput}
        value={quantity}
      />
      {errorMsg && <p className="error-color">{errorMsg}</p>}
    </div>
  );
};

export default CartProductSwitcher;
