"use client";
import "../Assets/Css/Wishlist.css";
import Image from "next/image";
import Fav from "@/app/assets/images/fav.svg";
import Faved from "@/app/assets/images/faved.svg";
import Cookies from "js-cookie";
import WishlistApiService from "@/Core/Marketplace/Wishlist/Application/WishlistApiService";
import { useRouter, usePathname } from "next/navigation";
import { useState } from "react";
import ProductApiService from "@/Core/Marketplace/Products/Application/ProductApiService";
import ProductEvent from "@/Core/Marketplace/Products2/Domain/ProductEvent";
import ProductEventItem from "@/Core/Marketplace/Products2/Domain/ProductEventItem";
import GetProductEvent from "@/app/hooks/GetProductEvent";

interface Props {
  productId: number;
  wishlisted: boolean;
  setWishlisted?(wishlisted: boolean): void;
}

const WishlistButton: React.FC<Props> = ({
  productId,
  wishlisted,
  setWishlisted,
}) => {
  const router = useRouter();
  const pathname = usePathname();
  const [loading, setLoading] = useState<boolean>(false);

  const addWishlist = async (): Promise<void> => {
    if (loading) return;
    setLoading(true);

    const loged = Cookies.get("auth_token");
    const user_id = Cookies.get("user_id");

    if (!loged || !user_id) {
      const lang = Cookies.get("language") || "en";
      const url = `/${lang}/login`;
      Cookies.set("redirect", pathname);
      router.push(url);
      setLoading(false);
      return;
    }

    // If already wishlisted, remove it
    if (wishlisted && setWishlisted) {
      setWishlisted(false);
      setLoading(false);
      return;
    }

    const isWishlisted = await WishlistApiService.addProductToWishlist(
      productId,
      parseInt(user_id)
    );

    if (isWishlisted && setWishlisted) {
      // * All of this is to send the event to GTM
      const itemEvent = await GetProductEvent(productId);
      if (typeof window !== "undefined" && itemEvent) {
        window.dataLayer.push({ ecommerce: null });
        window.dataLayer.push({
          event: "add_to_wishlist_2",
          ecomerce: itemEvent,
        });
      }
      // * End of GTM event
      setWishlisted(true);
    }

    setLoading(false);
  };

  return (
    <div className="wish-btn">
      <button onClick={addWishlist} className="wishlist-btn">
        {loading ? (
          <div className="loading-spinner-xs" />
        ) : (
          <Image
            src={wishlisted ? Faved : Fav}
            alt="Add to wishlist"
            width={24}
            height={24}
          />
        )}
      </button>
    </div>
  );
};

export default WishlistButton;
