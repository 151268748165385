import ProductApiService from "@/Core/Marketplace/Products/Application/ProductApiService";
import ProductEvent from "@/Core/Marketplace/Products2/Domain/ProductEvent";
import ProductEventItem from "@/Core/Marketplace/Products2/Domain/ProductEventItem";

const GetProductEvent = async (
  productId: number
): Promise<ProductEvent | null> => {
  // Declare function as async
  const item = await ProductApiService.getProductById(productId);
  if (item) {
    const eventMeta = [
      new ProductEventItem(
        item.name,
        item.sku,
        item.prices[0].clientPrice,
        item.prices[0].currency,
        "",
        item?.mainCategory?.name,
        "",
        "",
        "",
        1
      ),
    ];

    const itemEvent = new ProductEvent(
      item.prices[0].currency,
      item.prices[0].price,
      eventMeta
    );

    return itemEvent;
  }
  return null;
};

export default GetProductEvent;
