import t from "@/app/hooks/lang";
import Image from "next/image";
import Truck from "../Assets/Images/truck.svg";
import Separator from "../Assets/Images/separator.svg";
import GetCountryByCode from "@/app/hooks/GetCountryByCode";

interface Props {
  lang: string;
  country: string;
}

const CartSendingBanner = ({ lang, country }: Props) => {
  return (
    <div className="cart-sending-info-container">
      <div className="cart-sending-info">
        <Image src={Truck} alt="sending" width={20} height={20} />
        {country && country !== "" ? (
          <p>
            {t("sendingFrom", lang)} {GetCountryByCode(country)}:
          </p>
        ) : (
          <p>{t("estimatedShippingTime", lang)}</p>
        )}
        <div className="cart-sending-info-desktop">
          <span>{t("nationalsSentTime", lang)}</span>
          <Image src={Separator} alt="separator" />
          <span>{t("interationalsSentTime", lang)}</span>
        </div>
      </div>
      <div className="cart-sending-info-mobile">
        <ul>
          <li>{t("nationalsSentTime", lang)}</li>
          <li>{t("interationalsSentTime", lang)}</li>
        </ul>
      </div>
    </div>
  );
};

export default CartSendingBanner;
