import t from "@/app/hooks/lang";

interface Props {
  lang: string;
}

const CartDifferentSellerBanner: React.FC<Props> = ({ lang }) => {
  return (
    <div className="cart-different-seller-banner">
      <p>{t("differentPackageMsg", lang)}</p>
    </div>
  );
};

export default CartDifferentSellerBanner;
