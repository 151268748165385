import WishList from "../Domain/WishList";
import Cookies from "js-cookie";
const marketplace = process.env.NEXT_PUBLIC_MARKETPLACE_URL;

export default class WishlistApiService {
  static async getWishlistItemsCount(userId: number): Promise<void> {
    try {
      const response = await fetch(
        `${marketplace}/wp-json/product/get-wishlist-items-count?user_id=${userId}`
      );
      const data = await response.json();
      const result = data.result;
      if (Array.isArray(result)) {
        Cookies.set("wishlist", JSON.stringify(result));
      } else {
        Cookies.set("wishlist", JSON.stringify(WishList.default));
      }
    } catch (error) {
      Cookies.set("wishlist", JSON.stringify(WishList.default));
      console.error("Error fetching wishlist items: ", error);
    }
  }

  static async checkProductInWishlist(productId: number): Promise<boolean> {
    try {
      const userId = Cookies.get("user_id");
      const response = await fetch(
        `${marketplace}/wp-json/product/get-wishlist-items-count?user_id=${userId}&product_id=${productId}`
      );
      const data = await response.json();
      const result = data.result.productInWishlist;
      return result;
    } catch (error) {
      console.error("Error checking if product is wishlisted: ", error);
      return false;
    }
  }

  static async addProductToWishlist(
    productId: number,
    userId: number
  ): Promise<boolean> {
    try {
      await fetch(
        `${marketplace}/wp-json/product/add-item-to-wishlist?user_id=${userId}&product_id=${productId}`
      );

      // Add product to wishlist cookie
      const wishlistCookie = Cookies.get("wishlist");
      const wishlistedProducts: number[] = wishlistCookie
        ? JSON.parse(wishlistCookie)
        : [];
      wishlistedProducts.push(productId);
      Cookies.set("wishlist", JSON.stringify(wishlistedProducts));

      return true;
    } catch (err) {
      console.error("Failed to add product to wishlist", err);
      return false;
    }
  }
}
