import ApiServiceGlobal from "@/Core/Shared/Application/ApiServiceGlobal";
import Product from "../Domain/Product";
import Cookies from "js-cookie";
import ReadProduct from "../ReadProduct/Domain/ReadProduct";

export default class ProductApiService extends ApiServiceGlobal {
  static async getProducts(page: number, limit: number): Promise<Product[]> {
    const response = await this.fetchWithoutAuthorization(
      `products/get-all?page=${page}&limit=${limit}`
    );
    const data = await response.json();
    return data.result;
  }

  static async getProductsByShop(
    page: number,
    limit: number
  ): Promise<Product[]> {
    const response = await this.fetchWithAuthorization(
      `products/get-all-by-shop?page=${page}&limit=${limit}`
    );
    const data = await response.json();
    return data.result;
  }

  static async getProductsProjection(
    categoryName: string | null
  ): Promise<Product[]> {
    const lang = Cookies.get("language") ?? "en";
    const filters = categoryName !== null ? `categoryName=${categoryName}` : ``;

    const response = await this.fetchWithoutAuthorization(
      `products/projection/${lang}?${filters}`
    );
    const data = await response.json();
    return data.result;
  }

  static async getProductsProjectionByShop(): Promise<Product[]> {
    const response = await this.fetchWithAuthorization(
      "products/get-all-projections-by-shop"
    );
    const data = await response.json();
    return data.result;
  }

  static async getCountAllProducts(): Promise<number> {
    const response = await this.fetchWithoutAuthorization("products/count");
    const data = await response.json();
    return data.result.totalProducts;
  }

  static async getProductsByFilters(
    filter: string | null,
    page: number,
    limit: number,
    shopId: string | null
  ): Promise<Product[]> {
    let filters = `page=${page}&limit=${limit}`;
    filters += filter !== null ? `&filter=${filter}` : ``;
    filters += shopId !== null ? `&shopId=${shopId}` : ``;

    try {
      const response = await this.fetchWithoutAuthorization(
        `products/filters?${filters}`
      );
      const data = await response.json();
      return data.result;
    } catch (err) {
      console.error("Failed to fetch products", err);
      return [];
    }
  }

  static async getProductsWithSKUBySlug(productSlug: string): Promise<any[]> {
    const lang = Cookies.get("language") ?? "en";
    try {
      const response = await this.fetchWithoutAuthorization(
        `products/get-all-group-sku-by-slug/${lang}/${productSlug}`
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async productNewClick(query: string, dfid: string): Promise<void> {
    try {
      await fetch(
        `https://eu1-search.doofinder.com/5/stats/click?dfid=${dfid}&query=${query}`
      );
    } catch (error) {
      console.error("Error clicking the product", error);
    }
  }

  static async getProductsRecentlyViewed(
    page?: number,
    limit?: number
  ): Promise<ReadProduct[]> {
    try {
      const sessionId = Cookies.get("sessionId") ?? "";
      const userId = Cookies.get("userId") ?? "";
      const lang = Cookies.get("language") ?? "en";

      if (!limit) {
        limit = 10;
      }
      if (!page) {
        page = 1;
      }

      const response = await this.fetchWithoutAuthorization(
        `products/recently-viewed?page=${page}&limit=${limit}`,
        {
          headers: {
            "x-session-id": sessionId,
            "x-user-id": userId,
            lang,
          },
        }
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.log(error);
      console.error("Error getting recently viewed products", error);
      return [];
    }
  }

  static async getRecomendedProducts(
    page?: number,
    limit?: number
  ): Promise<ReadProduct[]> {
    try {
      const sessionId = Cookies.get("sessionId") ?? "";
      const userId = Cookies.get("userId") ?? "";
      const lang = Cookies.get("language") ?? "en";

      if (!limit) {
        limit = 10;
      }
      if (!page) {
        page = 1;
      }

      const response = await this.fetchWithoutAuthorization(
        `products/recently-viewed?page=${page}&limit=${limit}`,
        {
          headers: {
            "x-session-id": sessionId,
            "x-user-id": userId,
            lang,
          },
        }
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.log(error);
      console.error("Error getting recently viewed products", error);
      return [];
    }
  }
}
