export default class ProductEventItem {
  item_name: string;
  item_id: string | number;
  price: number;
  currency: string;
  item_brand: string;
  item_category: string;
  item_category2: string;
  item_category3: string;
  item_category4: string;
  quantity: number;

  constructor(
    item_name: string,
    item_id: string | number,
    price: number,
    currency: string,
    item_brand: string,
    item_category: string,
    item_category2: string,
    item_category3: string,
    item_category4: string,
    quantity: number
  ) {
    this.item_name = item_name;
    this.item_id = item_id;
    this.price = price;
    this.currency = currency;
    this.item_brand = item_brand;
    this.item_category = item_category;
    this.item_category2 = item_category2;
    this.item_category3 = item_category3;
    this.item_category4 = item_category4;
    this.quantity = quantity;
  }
}
