import ApiServiceGlobal from "@/Core/Shared/Application/ApiServiceGlobal";
import Product from "../Domain/Product";
import Cookies from "js-cookie";
import ReadProduct from "../ReadProduct/Domain/ReadProduct";

export default class ProductApiService extends ApiServiceGlobal {
  static async getProducts(page: number, limit: number): Promise<Product[]> {
    const response = await this.fetchWithoutAuthorization(
      `products/get-all?page=${page}&limit=${limit}`
    );
    const data = await response.json();
    return data.result;
  }

  static async getProductsByShop(
    page: number,
    limit: number
  ): Promise<Product[]> {
    const response = await this.fetchWithAuthorization(
      `products/get-all-by-shop?page=${page}&limit=${limit}`
    );
    const data = await response.json();
    return data.result;
  }

  static async getProductsProjection(
    categoryName: string | null
  ): Promise<Product[]> {
    const lang = Cookies.get("language") ?? "en";
    const filters = categoryName !== null ? `categoryName=${categoryName}` : ``;

    const response = await this.fetchWithoutAuthorization(
      `products/projection/${lang}?${filters}`
    );
    const data = await response.json();
    return data.result;
  }

  static async getProductsProjectionByShop(): Promise<Product[]> {
    const response = await this.fetchWithAuthorization(
      "products/get-all-projections-by-shop"
    );
    const data = await response.json();
    return data.result;
  }

  static async searchProductsByShop(
    page: number = 1,
    limit: number = 50,
    sortBy: string = "default",
    filter?: string,
    status?: string
  ): Promise<any> {
    let url = "products/get-by-shop?";

    if (page) url += `page=${page}&`;
    if (limit) url += `limit=${limit}&`;
    if (filter) url += `filter=${[filter]}&`;
    if (status) url += `status=${status}&`;
    if (sortBy) url += `sortBy=${sortBy}&`;

    const response = await this.fetchWithAuthorization(url);
    const data = await response.json();
    return data.result;
  }

  static async getCountAllProducts(): Promise<number> {
    const response = await this.fetchWithoutAuthorization("products/count");
    const data = await response.json();
    return data.result.totalProducts;
  }

  static async getProductsByFilters(
    filter: string | null,
    page: number,
    limit: number,
    shopId: string | null
  ): Promise<Product[]> {
    let filters = `page=${page}&limit=${limit}`;
    filters += filter !== null ? `&filter=${filter}` : ``;
    filters += shopId !== null ? `&shopId=${shopId}` : ``;

    try {
      const response = await this.fetchWithoutAuthorization(
        `products/filters?${filters}`
      );
      const data = await response.json();
      return data.result;
    } catch (err) {
      console.error("Failed to fetch products", err);
      return [];
    }
  }

  static async getProductsWithSKUBySlug(productSlug: string): Promise<any[]> {
    const lang = Cookies.get("language") ?? "en";
    try {
      const response = await this.fetchWithoutAuthorization(
        `products/get-all-group-sku-by-slug/${lang}/${productSlug}`
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  static async getProductById(productId: number): Promise<Product | null> {
    try {
      const response = await this.fetchWithoutAuthorization(
        `products/${productId}`
      );
      const data = await response.json();
      return data.result;
    } catch (error) {
      console.error(`Error fetching product with ID ${productId}:`, error);
      return null;
    }
  }
}
