import t from "@/app/hooks/lang";
import CartSuborders from "./CartSuborders";
import CartContent from "../../../Domain/CartContent";

interface Props {
  cartContent: CartContent;
  lang: string;
  inWidget?: boolean;
  setCartContent: (CartContent: CartContent) => void;
}

const CartContentPage: React.FC<Props> = ({
  cartContent,
  lang,
  inWidget,
  setCartContent,
}) => {
  const getProductsCount = (): number => {
    return cartContent.suborders.reduce((count, suborder) => {
      return count + suborder.items.length;
    }, 0);
  };

  return (
    <div className="cart-header">
      <div className={`cart-title ${inWidget ? "cart-title-widget" : ""}`}>
        <h1>{t("cart", lang).toUpperCase()}</h1>
        {cartContent.suborders.length > 0 && (
          <p>
            ({getProductsCount()} {t("products", lang)})
          </p>
        )}
      </div>
      {cartContent.suborders.length > 0 ? (
        <div className={inWidget ? "cart-widget-content" : ""}>
          <CartSuborders
            suborders={cartContent.suborders}
            lang={lang}
            inWidget={inWidget}
            setCartContent={setCartContent}
          />
        </div>
      ) : (
        <div
          className={inWidget ? "cart-widget-content cart-empty" : "cart-empty"}
        >
          <h2>{t("cartEmpty", lang)}</h2>
          <a href={`/${lang}/c/shop`}>
            <button>{t("shopFabrics", lang)}</button>
          </a>
        </div>
      )}
    </div>
  );
};

export default CartContentPage;
