import React, { useState, useEffect, useCallback } from "react";
import WishlistButton from "@/Core/Marketplace/Wishlist/Infrastructure/Ui/Components/WishlistButton";
import CartProductSwitcher from "./CartProductSwitcher";
import CartItem from "../../../Domain/CartItem";
import onImageError from "@/app/hooks/onImageError";
import CartApiService from "../../../Application/CartApiService";
import Cookies from "js-cookie";
import { v4 as uuidv4 } from "uuid";
import FormatPrice from "@/app/hooks/FormartPrice";
import { useMediaQuery } from "react-responsive";
import WishlistApiService from "@/Core/Marketplace/Wishlist/Application/WishlistApiService";
import CartContent from "../../../Domain/CartContent";
import t from "@/app/hooks/lang";

interface Props {
  item: CartItem;
  inWidget?: boolean;
  setCartContent?: (CartContent: CartContent) => void;
}

const ProductPrice: React.FC<{ price: number; quantity: number }> = React.memo(
  ({ price, quantity }) => <p>{FormatPrice(price * quantity)} €</p>
);

const RemoveButton: React.FC<{ onClick: () => void; inWidget?: boolean }> =
  React.memo(({ onClick, inWidget }) => (
    <p
      onClick={onClick}
      style={{ marginTop: "40px" }}
      className={`cart-remove ${inWidget ? "cart-remove-widget" : ""}`}
    >
      {t("remove")}
    </p>
  ));

const CartProduct = ({ item, inWidget, setCartContent }: Props) => {
  const noImage = process.env.NEXT_PUBLIC_NOIMAGE ?? "";
  const isMobile = inWidget ?? useMediaQuery({ query: "(max-width: 768px)" });
  const min = item.minimumBuyable ?? 1;
  const [quantity, setQuantity] = useState<number>(item.quantity);
  const [loading, setLoading] = useState<boolean>(false);
  const [wishlisted, setWishlisted] = useState<boolean>(false);

  const sessionId = Cookies.get("sessionId") ?? uuidv4();
  const userId = Cookies.get("userId") ?? null;

  useEffect(() => {
    const checkProductInWishlist = async () => {
      try {
        const wishlisted = await WishlistApiService.checkProductInWishlist(
          item.productId
        );
        setWishlisted(wishlisted);
      } catch (error) {
        console.error("Failed to check product in wishlist:", error);
      }
    };

    checkProductInWishlist();
  }, [item.productId]);

  useEffect(() => {
    if (quantity !== item.quantity) {
      if (quantity === 0) {
        removeProduct();
      } else {
        updateCart(quantity);
      }
    }
  }, [quantity]);

  const updateCart = useCallback(
    async (newQuantity: number): Promise<void> => {
      try {
        await CartApiService.addToCart(
          item.productId,
          newQuantity,
          sessionId,
          userId,
          true
        );

        if (setCartContent) {
          const content: CartContent = await CartApiService.getCartContent();
          setCartContent(content);
        }
      } catch (error) {
        console.error("Failed to update cart:", error);
      }
    },
    [item.productId, sessionId, userId, setCartContent]
  );

  const removeProduct = useCallback(async (): Promise<void> => {
    setLoading(true);
    await updateCart(0);
    setLoading(false);
  }, [updateCart]);

  const getProductImage = (img: string): string => (img ? img : noImage);

  return loading ? (
    <div className="loading-spinner-medium loading-spinner-cart" />
  ) : (
    <div className="row">
      <div className={`margin-cart-product col-12 ${!inWidget && "col-md-8"}`}>
        <div className="row">
          <div
            className={`cart-product-data ${
              inWidget ? "cart-product-data-widget col-9" : "col-md-7 col-9"
            }`}
          >
            <img
              onError={onImageError}
              src={getProductImage(item.mainImg)}
              alt={item.name}
              width={inWidget ? 60 : 95}
              height={inWidget ? 60 : 95}
            />
            <div>
              <p>{item.name}</p>
              <p>
                {FormatPrice(item.price)} €/m ({t("withoutVAT").toLowerCase()})
              </p>
            </div>
          </div>
          {isMobile && (
            <div className="col-3 align-right">
              <ProductPrice price={item.price} quantity={quantity} />
            </div>
          )}
          <div
            className={`col-12 product-price-column ${!inWidget && "col-md-4"}`}
          >
            {isMobile ? (
              <div className="row">
                <div className="col-2">
                  <WishlistButton
                    productId={item.productId}
                    wishlisted={wishlisted}
                    setWishlisted={setWishlisted}
                  />
                </div>
                <div className={inWidget ? "col-6" : "col-7"}>
                  <CartProductSwitcher
                    quantity={quantity}
                    setQuantity={setQuantity}
                    updateCart={updateCart}
                    min={min}
                    max={item.stock}
                  />
                </div>
                <div className={inWidget ? "col-4" : "col-3"}>
                  <RemoveButton onClick={removeProduct} inWidget={inWidget} />
                </div>
              </div>
            ) : (
              <CartProductSwitcher
                quantity={quantity}
                setQuantity={setQuantity}
                updateCart={updateCart}
                min={min}
                max={item.stock}
              />
            )}
          </div>
        </div>
      </div>
      <div className="cart-subtotal col-md-4 col-12">
        {!isMobile && <ProductPrice price={item.price} quantity={quantity} />}
        {!isMobile && (
          <div>
            <WishlistButton
              productId={item.productId}
              wishlisted={wishlisted}
              setWishlisted={setWishlisted}
            />
            <RemoveButton onClick={removeProduct} inWidget={inWidget} />
          </div>
        )}
      </div>
    </div>
  );
};

export default CartProduct;
